<template>
  <div class="plaindetails-box">
    <!------- tooltip box start ------->
    <div class='tooltipmainbox'>
      <button class="btn-tooltip" @click="toggleTooltip" v-if="baggagePriceInfo"><i class="fa fa-circle-info"></i></button>
      <div class="tooltip active" v-show="showTooltip">
          <h6>{{ $t("flight-only.baggage-details-title") }}</h6>
          <ul>
            <li v-if="trolleyCostIncluded">
                <img :src="`${speedSizeDomain}/assets/img/img12.png`" alt="trolley" class="icon" />
                {{$t("flight-only.trolley-included", {weight: maxTrolleyWeight})}}
            </li>
            <li v-else>
                <img :src="`${speedSizeDomain}/assets/img/img14.png`" alt="trolley" class="icon" />
                {{$t("flight-only.trolley-not-included", {weight: maxTrolleyWeight})}}
            </li>
            <li v-if="suitcaseCostIncluded">
                <img :src="`${speedSizeDomain}/assets/img/img11.png`" alt="suitcase" class="icon" />
                {{$t("flight-only.suitcase-included", {weight: maxSuitcaseWeight})}}
            </li>
            <li v-else>
                <img :src="`${speedSizeDomain}/assets/img/img11-no.png`" alt="suitcase" class="icon" />
                {{$t("flight-only.suitcase-not-included", {weight: maxSuitcaseWeight})}}
            </li>
          </ul>
      </div>
    </div>
    <!------- tooltip box stop ------->
    <figure class="logo"><img v-if="!flight.FlightDetail[idxFlightDetail].airlineNotConfirmed" :src="logoFlight" alt="" title="" /></figure>
    <div class="plain-date">
      <div class="d-flex justify-content-between align-items-center" v-if="direction === 'departure'">
        <div class="box">
          <h4>{{ departureDate }}</h4>
          <h3>{{ departureTime }}</h3>
          <h4>{{ departureRoute }}</h4>
        </div>
        <figure class="flight-box">
          <img :src="`${speedSizeDomain}/assets/img/app/flight-img1.png`" alt="#" title="" />
        </figure>
        <div class="box">
          <h4>{{ arriveDate }}</h4>
          <h3>{{ arriveTime }}</h3>
          <h4>{{ arriveRoute }}</h4>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center" v-else>
        <div class="box">
          <h4>{{ arriveDate }}</h4>
          <h3>{{ arriveTime }}</h3>
          <h4>{{ arriveRoute }}</h4>
        </div>
        <figure class="flight-box">
          <img class="arrive" :src="`${speedSizeDomain}/assets/img/app/flight-img1.png`" alt="#" title="" />
        </figure>
        <div class="box">
          <h4>{{ departureDate }}</h4>
          <h3>{{ departureTime }}</h3>
          <h4>{{ departureRoute }}</h4>
        </div>
      </div>
    </div>
    <p v-if="isConnectFlight">{{ $t("flight-only.stopover", { count: 1 }) }}</p>
    <p><span>{{ descriptionFlight }}<br/>{{ codeFlight }}</span><br/>{{ authorizeFlight }}</p>
    <h5>{{ Math.min(flight.avl1, flight.avl2) > 8 ? '' : `${$t("search-result.number-seats-left")}: ${Math.min(flight.avl1,flight.avl2)}`}}</h5>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  name: 'SingleFlightItemApp',
  mixins: [imageUrlMixin],
  props: {
    flight: {
      type: Object,
      default: null,
    },
    baggagePriceInfo: {
      type: Object,
      default: null,
    },
    showExtraInfo: {
      type: Boolean,
      default: false,
    },
    direction: {
      type: String,
      default: 'departure',
    },
  },
  data() {
    return {
      showTooltip: false,
      maxSuitcaseWeight: 0,
      maxTrolleyWeight: 0,
    };
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
    }),
    idxFlightDetail() {
      return this.direction === 'departure' ? 0 : 1;
    },
    isConnectFlight() {
      return this.flight.FlightDetail[this.idxFlightDetail]?.FL_Flt_Via !== '' ?? false;
    },
    descriptionFlight() {
      return this.flight.FlightDetail[this.idxFlightDetail]?.airlineInfo?.desc1 ?? '';
    },
    codeFlight() {
      return this.flight.FlightDetail[this.idxFlightDetail].airlineNotConfirmed && this.flight.FlightDetail[this.idxFlightDetail].flightTimeNotConfirmed ? '' : `${this.flight.FlightDetail[this.idxFlightDetail].airlineInfo.code || this.flight.FlightDetail[this.idxFlightDetail].FL_AIRLINE} - ${this.flight.FlightDetail[this.idxFlightDetail].FL_Flt_Number}`;
    },
    authorizeFlight() {
      return this.flight.FlightDetail[this.idxFlightDetail].Authorization_msg;
    },
    logoFlight() {
      return (this.flight.FlightDetail[this.idxFlightDetail].airlineInfo && this.flight.FlightDetail[this.idxFlightDetail].airlineInfo.desc2) || `${this.speedSizeDomain}/assets/img/flying.png`;
    },
    departureRoute() {
      return (this.flight.translations && this.flight.translations.flightDestinationName[this.flight.FlightDetail[this.idxFlightDetail].FL_From_Route] && this.flight.translations.flightDestinationName[this.flight.FlightDetail[this.idxFlightDetail].FL_From_Route][this.lang]) || this.flight.FlightDetail[this.idxFlightDetail].FL_From_Route;
    },
    departureDate() {
      return this.formatDate(this.flight.FlightDetail[this.idxFlightDetail].FL_Date);
    },
    departureTime() {
      return this.flight.FlightDetail[this.idxFlightDetail].FL_Dep_Hour;
    },
    arriveRoute() {
      return (this.flight.translations && this.flight.translations.flightDestinationName[this.flight.FlightDetail[this.idxFlightDetail].FL_To_Route] && this.flight.translations.flightDestinationName[this.flight.FlightDetail[this.idxFlightDetail].FL_To_Route][this.lang]) || this.flight.FlightDetail[this.idxFlightDetail].FL_To_Route;
    },
    arriveDate() {
      return this.formatDate(this.flight.FlightDetail[this.idxFlightDetail].landingDate);
    },
    arriveTime() {
      return this.flight.FlightDetail[this.idxFlightDetail].FL_Arrv_Hour;
    },
    trolleyCostIncluded() {
      if (!this.baggagePriceInfo) return true;

      if(this.direction === 'departure') {
        return (this.baggagePriceInfo.trolleyPrice === null);
      } else {
        return (this.baggagePriceInfo.trolleyReturnPrice === null);
      }
    },
    suitcaseCostIncluded() {
      if (!this.baggagePriceInfo) return true;

      if(this.direction === 'departure') {
        return (this.baggagePriceInfo.suitcasePrice === null);
      } else {
        return (this.baggagePriceInfo.suitcasePrice === null);
      }
    },
  },
  created() {
    this.maxTrolleyWeight = +this.flight.FlightDetail[this.idxFlightDetail]?.FL_Max_Weight_Trolley || 0;
    this.maxSuitcaseWeight = this.flight.FlightDetail[this.idxFlightDetail]?.FL_Max_Weight || 0;
  },
  methods: {
    toggleTooltip() {
      this.showTooltip = !this.showTooltip;
    },
    formatDate(strDate) {
      return dayjs(strDate).format('DD.MM.YY');
    },
  },
};
</script>

<style>
  .heading h1{
    font-weight: bold;
  }
  .plaindetails-box .tooltipmainbox .tooltip.active {
      display: inline-block;
      opacity: 1;
  }
  .plaindetails-box .logo img{
    max-height: 50px;
    width: auto;
  }
  .plaindetails-box .plain-date .flight-box img.arrive{
    -webkit-transform: scaleX(-1);
    translform: scaleX(-1);
  }
</style>

<style lang="less" scoped>
  .bonauf-desktop {
    .plaindetails-box {
      .tooltipmainbox {
        .btn-tooltip {
          font-size: 22px;
        }
        .tooltip ul li {
            margin-bottom: 10px;
            font-size: 12px;
            line-height: 20px;
        }
      }
    }
  }
</style>
